import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { HelperService } from 'src/app/core/helpers/helper.service';

@Component({
  selector: 'app-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    private _helper: HelperService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    // this._helper.globleLoader.subscribe((resp:boolean)=>{
    //   this.loading = resp;
    // })
    this.cdRef.detectChanges();
  }
}
