import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from '../app-routing.module';
import { AppComponent } from './app/app.component';
import { BreadcrumbComponent } from './app/common/breadcrumb/breadcrumb.component';
import { FooterComponent as MainFooterComponent } from './app/common/footer/footer.component';
import { HeaderComponent as MainHeaderComponent } from './app/common/header/header.component';
import { SidebarComponent } from './app/common/sidebar/sidebar.component';
import { AuthComponent } from './auth/auth.component';
import { AuthBreadcrumbComponent } from './auth/common/auth-breadcrumb/auth-breadcrumb.component';
import { FooterComponent } from './auth/common/footer/footer.component';
import { HeaderComponent } from './auth/common/header/header.component';

const component = [AuthComponent];

@NgModule({
  declarations: [
    AuthComponent,
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MainHeaderComponent,
    MainFooterComponent,
    SidebarComponent,
    BreadcrumbComponent,
    AuthBreadcrumbComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    TranslateModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    MainHeaderComponent,
    MainFooterComponent,
  ],
})
export class LayoutsModule {}
