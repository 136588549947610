import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private _lang: LanguageService, private auth: AuthService) {}

  /**
   * @return Http Header
   * App Api Header
   */
  get header() {
    const token = this.auth.getLocalDataByKey('token');
    let header = new HttpHeaders()
      .set('Authorization', `Bearer ${token}`)
      .set('accept', 'application/json')
      .set('lang', this._lang.currentLanguage());
    return header;
  }
}
