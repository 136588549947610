import { environment as envLocal } from './environments/environment';
import { environment as envProduction } from './environments/environment.prod';

//enable app into production mode
let production = false;
let environment = production ? envProduction : envLocal;
export const env = {
  PRODUCTION: production,
  PATTERN: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
  APP_NAME: environment.APP_NAME,
  APP_URL: environment.APP_URL,
  API_URL: environment.API_URL,
  ASSET_URL: environment.ASSET_URL,
  API_GROUP_BACKEND: environment.API_GROUP_BACKEND,
  API_GROUP_FRONTEND:'frontend',
  APP_LANG: environment.APP_LANG,
  APP_DATA_KEY: 'QD_BACKEND_DATA', // Having Keys user, utilities, token, lang
};
