import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/core/helpers/http.service';
import { env } from 'src/env';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private router: Router,
    private _http: HttpService,
    private http: HttpClient
  ) {}

  /**
   *
   * @param user
   * @returns http response
   */
  login(user: any) {
    return this.http.post(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/auth/login`,
      user
    );
  }

  /**
   *
   * @param password
   * @returns http response
   */
  changePassword(password: any) {
    return this.http.post(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/auth/change-password`,
      password,
      { headers: this._http.header }
    );
  }

  /**
   *
   * @param email
   * @returns http resopone
   * forgot password
   */
  forgotpassword(email: any) {
    return this.http.post(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/auth/forgot-password`,
      email
    );
  }

  /**
   *
   * @param email
   * @returns http resopone
   * forgot password
   */
  getUtility() {
    return this.http.get(`${env.API_URL}/utilities`);
  }
}
